


import React, { useContext, useEffect } from 'react'
import Col from "react-bootstrap/Col"
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import { ReactTable } from '@stem-sims/nexus'
import { FilterByInstructor } from '../../../components/StudentRoster'
import { PopulatedStudent } from '../../../shared/types/studentTypes'
import RecordsContext from '../RecordsContext'

type RecordsRosterProps = {
    columns: any
    data: PopulatedStudent[]
    clickRow?: ((row:any) => void) | undefined
}

function isArchived(student) {
    const licenses = student.licenses.filter((license) => license.status === "Archived")
    return licenses.length > 0 ? "archived" : ""
}

function isSelected(student, selectedStudentID) {
    return student.id.toString() === selectedStudentID ? "selectedStudent" : ""
}

export default function RecordsRoster({columns,data: students, clickRow=undefined}: RecordsRosterProps) {
    const context = useContext(RecordsContext)

    const { filteredStatus: status, filteredInstructor: filterValue, setFilteredStatus: setStatus, setFilteredInstructor: setFilterValue } = context

    const [instructors, setInstructors] = React.useState([])

    const formRef = React.useRef(null)
    
    const filteredStudentList = React.useMemo(() => {
        if (!students) return []
        const filteredStudents = students.filter((student) => {
            const showAllStudents = (status === "All")
            if (!showAllStudents && student.licenses.every((license) => license.status !== status)) {
                return false
            }
            if(filterValue) {
                if (!student.instructors || student.instructors.length === 0 || student.instructors.includes(filterValue) ) {
                    return true
                }
                return false
            }
            return true
        }).sort()
        context.setFilteredStudents(filteredStudents)
        return filteredStudents
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [students, filterValue, status])

    useEffect(() => {
        const instructorsByStudent: string[][] = students.map((s) => s.instructors) 
        const instructorNames = instructorsByStudent.flat(1)
        const uniqueInstructorsNames = instructorNames.filter((instruct, index, array) => (array.indexOf(instruct) === index)).sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        }) 
        setInstructors(uniqueInstructorsNames)
    },[students])

    useEffect(() => {
        formRef?.current?.reset()
    }, [students, status, filterValue])

    if(clickRow) var classString = "cursor-pointer"
    const customClass = (row) => `${classString} ${row.original.fidelityWarning && "fidelity-warning"} ${isArchived(row.original)} ${isSelected(row.original, context.studentID)}`

    return (<>
        <Row className='mb-3 px-2'>
            <Col md={6}>
                <FilterByInstructor instructors={instructors} setFilterValue={setFilterValue} defaultValue={filterValue} />
            </Col>
            <Col md={6}>
                <Form.Control key={status} as="select" defaultValue={status} onChange={(e) => setStatus(e.currentTarget.value)}>
                    <option value={"All"}>All</option>
                    <option value={"Active"}>Active</option>
                    <option value={"Archived"}>Archived</option>
                </Form.Control>
            </Col>
        </Row>
        <ReactTable columns={columns} bulkActions={[]} data={filteredStudentList} sortBy={[{ id: "name" }]} customClass={customClass} clickRow={clickRow} hidePagination />
    </>)
}
